import { DownOutlined } from '@ant-design/icons';
import styles from './index.module.less';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import Image from 'next/image';
import { useLocation } from 'react-use';
import { useEffect } from 'react';
import { useConfigProvider } from '@/context/ConfigProvider';
import { ga_logEvent } from '@/shared/ga';
import HeaderDropdown from '@/components/Layouts/HeaderDropdown';

export interface MarketplaceNavItem {
  text: string;
  path?: string;
  gaName?: string;
  icon?: { left: string; right: string };
  dropdownItems?: MarketplaceNavItem[];
  onClick?: (item: MarketplaceNavItem) => void;
}

const NavItem = ({ item, className }: { item: MarketplaceNavItem; className?: string }) => {
  const { isMobile } = useConfigProvider();
  const router = useRouter();

  const getColor = (pathStr: string) => {
    if (pathStr.includes('/blog')) {
      let end = router.pathname?.toLowerCase()?.includes(pathStr?.toLowerCase());
      return end ? '#428DFF' : `var(--app-text-color)`;
    } else {
      let end = router.pathname?.toLowerCase() == pathStr?.toLowerCase();
      return end ? '#428DFF' : `var(--app-text-color)`;
    }
  };

  const renderItem = (item: MarketplaceNavItem, index?: string | number) => {
    return (
      <div
        className={classNames(styles.navItemWarp, className)}
        key={index}
        onClick={(e) => {
          if (!isMobile) {
            e.stopPropagation();
          }
          if (item.gaName) {
            ga_logEvent(item?.gaName);
          }
          router.push(item?.path || '/');
        }}
      >
        <div
          className={isMobile ? styles.navItemMobileText : styles.navItemText}
          style={{
            color: getColor(item?.path),
          }}
        >
          {item.text}
        </div>
        {item.dropdownItems && (
          <DownOutlined
            style={{
              fontSize: '12px',
              marginLeft: '5px',
              color: getColor(item?.path),
            }}
          />
        )}
        {item.dropdownItems && (
          <div className={styles.navDropdown}>
            <div className={styles.navDropdownContent}>
              <HeaderDropdown content={item} />
            </div>
          </div>
        )}
      </div>
    );
  };

  return renderItem(item, `nav-item`);
};
export default NavItem;
