import { Divider, Drawer } from 'antd';
import { useToggle } from 'react-use';
import classNames from 'classnames';
import { useConfigProvider } from '@/context/ConfigProvider';
import styles from './index.module.less';
import { useCallback, type ReactNode } from 'react';
import { AppSettings } from '@/shared/app-common';
import { CloseOutlined } from '@ant-design/icons';
import { useUserProvider } from '@/context/UserProvider';
// import Link from 'next/link';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';

const NavHamburger = ({ children, iNode }: { children?: ReactNode; iNode?: ReactNode }) => {
  const { isMobile } = useConfigProvider();
  const { isLogin, onOpenLoginModal, onChangedLoginView, onSignOut } = useUserProvider();
  const [open, toggle] = useToggle(false);
  const router = useRouter();
  const { t } = useTranslation('common');

  const showLogin = (loginView: boolean) => {
    onOpenLoginModal(true);
    onChangedLoginView(loginView);
  };

  return (
    <div
      className={classNames(styles.navHamburgerWarp, {
        [styles.showClose]: open,
      })}
      onClick={toggle}
    >
      {iNode
        ? iNode
        : new Array(3).fill(1).map((_, index) => <span key={`burger-${index}`} className={styles.hamburgerLine} />)}
      <Drawer
        style={{ background: `var(--foreground-rgb)` }}
        title={
          <div>
            {/* <Image
              src={AppSettings.logoPath}
              alt={AppSettings.name}
              style={{ height: "32px" }}
              loading="lazy"
              width={32}
              height={32}
            /> */}
            <Image
              src={AppSettings.namePath}
              alt={AppSettings.name}
              style={{ height: '44px', marginLeft: '10px' }}
              loading="lazy"
              width={154}
              height={42}
            />
          </div>
        }
        closeIcon={<CloseOutlined style={{ color: `var(--app-text-color)` }} />}
        closable
        width={isMobile ? '85%' : 400}
        placement="left"
        onClose={() => {
          toggle(true);
        }}
        open={open}
        rootClassName={styles.leftMenuDrawerWarp}
      >
        {children}

        <div className={styles.footerCon}>
          <div className={styles.mediaSection}>
            <Link
              href="https://discord.gg/vmZfzh98gz"
              target="__block"
              className={styles.link}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <img src="https://res-front.pumpsoul.com/prod-erasa/image/contactUs/discord.svg" alt="discord" />
            </Link>
            <Link
              href="https://t.me/erasaprotect"
              target="__block"
              className={styles.link}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <img src="https://res-front.pumpsoul.com/prod-erasa/image/contactUs/tg.svg" alt="tg" />
            </Link>
            <Link
              href="https://x.com/erasaprotect"
              target="__block"
              className={styles.link}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <img src="https://res-front.pumpsoul.com/prod-erasa/image/contactUs/x.svg" alt="x" />
            </Link>
            <Link
              href="https://www.facebook.com/people/Erasa-AI/pfbid0GjWUeXcPgv67uL43FvfzprDtJ9wdP56iRcajSkrhZbQarTBPLRmfmREWNwxAgqC8l/"
              target="__block"
              className={styles.link}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <img src="https://res-front.pumpsoul.com/prod-erasa/image/contactUs/facebook.svg" alt="facebook" />
            </Link>
          </div>
          <div className={styles.mediaSection}>
            <Link href={`/privacy-policy`} className={styles.link}>
              {t('privacyPolicy')}
            </Link>
            <Link href={`/terms-us`} className={styles.link}>
              {t('termsOfUs')}
            </Link>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default NavHamburger;
